<template>
  <div
    id="usetype"
    class="page-container-table"
  >
    <div class="d-flex justify-content-end">
      <div class="header-action">
        <search
          class="search-input"
          @change="search"
        />
        <button-component
          class="ml-1"
          content-btn="Thêm kiểu người dùng"
          variant="primary"
          icon-btn="PlusIcon"
          @click="$router.push({ name: 'create-user-type' })"
        />
      </div>
    </div>

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataListUserType || []"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Chức năng -->
        <span v-if="props.column.field === 'actionFunction'">
          <span @click="$router.push({name: 'edit-user-type', params: {id: props.row.id}})">
            <feather-icon
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="openModalDelete(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.currentPage"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa kiểu người dùng-->
    <modal-delete-user-type
      :id="confirmModalId"
      @accept="deleteAction"
    />

  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ModalDeleteUserType from './components/ModalDeleteUserType.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    FeatherIcon,
    Search,
    ButtonComponent,
    ModalDeleteUserType,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'KIỂU NGƯỜI DÙNG',
          field: 'name',
          sortable: false,
          width: '40%',
        },
        {
          label: 'MÔ TẢ',
          field: 'description',
          sortable: false,
          width: '50%',
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
          width: '10%',
        },
      ],
      currentPage: 0,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      idDelete: '',
      dataListUserType: [],
      totalRecord: 0,
    }
  },
  created() {
    this.fetchListUserType(this.urlQuery)
  },
  methods: {
    ...mapActions('userType', [
      'doFetchUserTypeCombobox',
    ]),

    // Lấy danh sách người dùng phân trang
    async fetchListUserType(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_USERTYPE, { params: urlQuery })
      this.dataListUserType = data.data.pageLists
      this.totalRecord = data.data.totalRecord
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListUserType(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchListUserType(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchListUserType(this.urlQuery)
      }
    },

    // Mở modal xóa kiểu người dùng
    async openModalDelete(id) {
      this.idDelete = id
      await this.doFetchUserTypeCombobox(this.idDelete)
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction(val, validate) {
      console.log('val', val)
      const payload = {
        userTypeId: val,
        ids: this.idDelete,
      }
      validate.validate().then(async success => {
        if (success) {
          await axiosApiInstance.delete(ConstantsApi.DELETE_USERTYPE, { params: payload }).then(res => {
            if (res.status === 200) {
              this.$bvToast.toast('Xóa thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.$bvModal.hide('confirm-modal')
              this.fetchListUserType(this.urlQuery)
            } else {
              this.$bvToast.toast(`${res.data?.errors[0]}`, {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#usetype {
  .search-input {
    width: 400px;
  }
}
</style>
